import './App.css';
import WholeSite from './pages/WholeSite';

function App() {
  return (
    <WholeSite />
  );
}

export default App;
